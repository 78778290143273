// New Custom Quiz

.custom-quiz-page.new {

  .middlebar {
    display: none;
  }

  .l-main {
    @media #{$md} {
      padding-top: 10px;
    }

    @media #{$sm} {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &.new {
    .l-flex-row > * {
      margin-bottom: 0;
    }

    .sidebar {
      margin-left: 0;
    }
  }
}

#custom_quiz_creator {
  .loading-view {
    height: calc(var(--vh) - 193px);
    max-height: 75vh;

    @media #{$md} {
      height: calc(var(--vh) - 68px);
      max-height: var(--vh);
    }
  }
}

.custom-quiz-creator {
  display: flex;
  flex-direction: column;
  height: calc(var(--vh) - 193px);
  min-height: 450px;
  max-height: 75vh;

  @media #{$md} {
    height: calc(var(--vh) - 68px);
    min-height: 0;
    max-height: var(--vh);
  }

  .editable-title {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid $gray-border;

    .subtitle {
      font-size: $tiny-font-size;
      padding: 0 10px;
      color: $gray-text-light;
    }

    @media #{$sm} {
      margin-left: 32px;
      margin-right: 32px;
    }

    @media #{$xs} {
      margin-left: 15px;
      margin-right: 15px;
    }
  }

  .controls {
    display: flex;
    gap: 4px;
    flex-shrink: 0;

    @media #{$sm} {
      margin-top: 20px;
    }
  }

  .title {
    font-size: $lead-font-size;
    display: flex;
    align-items: center;
    line-height: 1.2;
    border-radius: 2px;
    margin: 0;

    .wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    .text {
      padding: 8px 10px 6.4px;
      border: 1px dashed transparent;
      transition: border-color .25s ease;

      &:hover:has(.fa:hover) {
        border-color: $gray-border;
      }
    }

    @media #{$md} {
      border-bottom: 0;
      margin: 0;
    }

    & span {
      position: relative;
      cursor: pointer;
      margin: -10px 0;
      padding: 10px 8px;
      transition: color .25s ease;
      font-size: 22px;

      &:hover {
        color: $logo-blue-darkened;
      }
    }

    & > form {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin: 0;
      width: 100%;

      & > input[type=text] {
        flex: 1;
        margin-right: 10px;
        color: $logo-blue;
        font-size: 24px;
        line-height: 1.2;
      }

      & > input[type=submit] {
        margin: 0;
      }

      & > .btn + a:not(.btn) {
        margin: 0;
        padding: 0px 20px;
      }
    }
  }

  .banner {
    margin-bottom: 20px;

    @media #{$md} {
      margin-bottom: 15px;
    }

    @media #{$sm} {
      margin-left: 32px;
      margin-right: 32px;
    }

    @media #{$xs} {
      margin-left: 15px;
      margin-right: 15px;
    }
  }
}

.topic-selector,
.structure-selector {
  display: flex;
  position: relative;
  flex-direction: row;
  min-height: 0;
  flex: 1;
  overflow: hidden;

  @media #{$sm} {
    flex-direction: column;
  }

  &-view {
    display: flex;
    flex-direction: column;
    min-height: 0;
    flex: 1;
  }

  &__header {
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;

    & > p {
      font-size: $small-font-size;
      line-height: 32px;
      margin-bottom: 0;
      flex: 1;
    }

    @media #{$md} {
      margin-bottom: 0;
      flex-direction: column;
      align-items: stretch;

      & > p {
        display: none;
      }

      & > .btn {
        margin-bottom: 15px;
      }
    }

    @media #{$sm} {
      margin-left: 32px;
      margin-right: 32px;
    }

    @media #{$xs} {
      margin-left: 15px;
      margin-right: 15px;
    }
  }

  &__options,
  &__summary {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    flex: 1;

    @media #{$md-lg-only} {
      margin-bottom: 10px;
    }

    @media #{$lg} {
      margin-bottom: 0;
    }
  }

  &__options {
    overflow: auto;
    margin-bottom: 10px;

    @media #{$lg} {
      margin-bottom: 0;
    }

    @media #{$sm} {
      margin-left: 32px;
      margin-right: 32px;
    }

    @media #{$xs} {
      margin-left: 15px;
      margin-right: 15px;
    }

  }

  &__search {
    border: 1px solid rgba(0, 0, 0, 0.08);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
    border-radius: 3px;
    margin-bottom: 10px;
    flex-shrink: 0;
  }

  &__results {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
    border-radius: 3px;
    border: 1px solid $gray-border;
  }

  .structure-selector__results {
    border-top: 0;
    border-radius: 0 0 3px 3px;

    .tag {
      cursor: pointer;
      background-color: inherit;

      & > svg {
        margin-right: 4px;
      }

      &:hover,
      &:focus {
        border-color: $logo-blue;
        color: $logo-blue;
      }
    }
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;

    & > li:last-child {
      min-height: 20px;
    }
  }

  ol {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .structure-item {
    & label {
      margin: 0;
      padding: 5px;
      line-height: 1.5;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    & > label {
      padding: 15px;
      border-bottom: 1px solid $gray-border;
    }
    & input + div {
      flex: 1;
    }
    & ul {
      margin: 0;
      list-style: none;
      padding: 5px 5px 5px 25px;
      border-bottom: 1px solid $gray-border;
    }
  }

  .topic-selector__item {
    padding: 15px;
    margin: 0;
    border-bottom: 1px solid $gray-border;
    background-color: $white;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }

  &__item-title {
    color: $logo-blue;
    margin-bottom: 5px;
    font-size: 14px;
    line-height: 17px;
  }

  &__item-meta {
    font-size: 11px;
    line-height: 15px;
    color: $gray-text-light;
  }

  &__item--selected {
    .topic-selector__toggle,
    .topic-selector__item-title,
    .topic-selector__item-meta {
      color: $gray-text-lightest;
    }

    .topic-selector__toggle {
      &--remove {
        color: $gray-text-light;
      }
    }
  }

  &__toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: $standard-font-size;
    cursor: pointer;
    color: $logo-blue;
  }

  &__title {
    position: relative;
    padding-bottom: 15px;
    margin: 16px 0 0;
    font-size: $standard-font-size;
    font-family: var(--light);
    color: $logo-blue;
    font-weight: normal;
    border-bottom: 1px solid $gray-border;
    transition-property: opacity, padding-bottom, max-height;
    transition-duration: .45s;

    & > .fa {
      margin-left: 5px;
    }

    & > &__long {
      @media #{$sm} {
        display: none;
      }
    }

    & > &__short {
      display: none;

      @media #{$sm} {
        display: inline-block;
      }
    }

    @media #{$sm} {
      font-size: $tiny-font-size;
      border-bottom: 0;
      margin: 15px 15px 0;
      padding: 0 30px 5px 0;
      cursor: pointer;
      overflow: hidden;
      max-height: var(--vh);
      opacity: 1;
      color: $white;
      line-height: 1.5;

      &--hidden {
        opacity: 0;
        padding: 0 35px 0 0;
        max-height: 0vh;
        margin: 0;
      }
    }
  }

  &__summary {
    margin-left: 30px;

    @media #{$sm} {
      background-color: $dark-bg-blue;
      margin-left: 0;
      align-items: center;
      display: block;
      padding: 0;
      z-index: 10;
      flex: initial;
    }
  }

  &__summary--condensed {
    text-align: center;
    justify-content: center;

    & .structure-selector__title {
      border: 0;
    }

    & .structure-selector__controls {
      margin-top: 0;
    }

    @media #{$sm} {
      .structure-selector__controls {
        padding-top: 0;
      }
    }
  }

  &__summary-toggle {
    position: absolute;
    right: 0;
    top: 0;
  }

  &__selection-list {
    list-style: none;
    padding: 0;
    margin: 0;
    flex: 1;
    overflow-y: scroll;
    width: 100%;
    transition: max-height .45s ease;

    @media #{$sm} {
      max-height: 0vh;
      overflow-y: hidden;
      padding: 0 15px;

      &--open {
        max-height: 50vh;
        overflow-y: scroll;
      }

      li:first-of-type > .topic-selector__item {
        border-radius: 3px 3px 0 0;
        margin-top: 5px;
      }

      li:last-of-type > .topic-selector__item {
        border-radius: 0 0 3px 3px;
      }
    }

    .topic-selector__item-title {
      color: $gray-text;
    }
    .topic-selector__item-meta {
      color: $gray-text-light;
    }
  }

  & input[type="checkbox"] {
    display: inline-block;
    margin-right: 10px;
  }

  &__filter-bar {
    display: flex;
    align-items: center;
    background-color: $gray-bg;
    font-size: $tiny-font-size;
    border: 1px solid $gray-border;
    padding: 8px 15px;
    font-family: var(--regular);
    cursor: initial;
    border-radius: 3px 3px 0 0;
    flex-shrink: 0;
  }

  .topic-selector__filter-bar {
    border-radius: 0;
    border: 0;
  }

  &__inline-label {
    margin-right: 10px;
    font-size: 12px;
    color: $gray-text;
    flex-shrink: 0;
  }

  &__topic-header {
    background-color: $gray-bg;
    font-size: 12px;
    border-bottom: 1px solid $gray-border;
    padding: 10px 15px;
    color: $gray-text;
    font-family: var(--medium);
    position: sticky;
    top: 0;
    z-index: 9;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.topic-header--collapsed {

      & + ol {
        display: none;
      }

      & > .fa {
        transform: rotate(-90deg);
      }
    }
  }

  .ais-SearchBox-reset {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: auto;
    aspect-ratio: 1;
    font-size: $standard-font-size;
    color: $gray-text-lightest;
    background-color: $white;
    border: none;
    cursor: pointer;
    transition: color .25s ease;

    &:hover,
    &:focus {
      color: $gray-text;
    }
  }

  & .ais-SearchBox,
  & .structure-selector__search {
    display: flex;
    margin: 0 0 10px;
    width: 100%;
    background-color: $white;
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
    position: relative;

    & form {
      width: 100%;

      & input[type="search"] {
        width: 100%;
      }
    }
  }

  & .ais-SearchBox {
    margin-bottom: 0;
    border: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    box-shadow: none;
  }

  & .ais-SearchBox-form {
    margin: 0;
  }

  & .ais-SearchBox-input,
  & .structure-selector__search-input {
    border: 0;
    padding: 0 15px 0;
    outline: none;
    color: $gray-text;
    flex: 1;
    -webkit-appearance: none;
    order: 1;
    height: 45px;
  }

  & .ais-SearchBox-submit {
    display: none;
  }

  &__summary-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    color: $gray-text-lightest;

    @media #{$sm} {
      display: none;
    }
  }

  &__controls {
    margin-top: auto;
    text-align: center;
    padding: 10px 0 0;
    display: flex;
    flex-wrap: wrap;

    @media #{$sm} {
      padding: 0;
      margin: 10px;
    }

    > .btn {
      margin: 10px 5px 0;
      flex: 1 1 0;

      @media #{$sm} {
        padding: 0 10px;
      }
    }
  }

  &__saving-controls {
    background-color: initial;
    text-align: center;
    font-size: 14px;
    margin-top: 10px;

    @media #{$sm} {
      background-color: $darker-bg-blue;
    }
  }

  &__saving-control {
    display: inline-block;
    padding: 10px;
  }

  & .topic-selector__controls {
    margin-top: 0;
  }
}

.structure-selector {

  &__title {
    @media #{$sm} {
      padding-right: 0;
    }
  }
}

.content-type-select {

  .select__item {
    &:first-of-type {
      color: $logo-blue;
    }

    & > input[type=checkbox] {
      margin-top: 3px;
    }
  }
}

.alert--info {
  background-color: $logo-blue-bg;
  color: $logo-blue;
  font-size: $tiny-font-size;
  padding: 15px;
  margin: 0 0 30px;

  a {
    text-decoration: underline;
    font-family: var(--medium);
  }

  @media #{$md} {
    margin-bottom: 15px;
  }
}
