.custom-quiz-page,
.custom-quiz-show,
.custom-quiz-listing-page {
    @media #{$lg} {
      & .sidebar  {
        display: none;
      }
      & .content {
        margin-left: 0;
      }
    }
}

.saved-quizzes-title {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  > .btn {
    margin: 0 0 0 auto;

    @media #{$md} {
      margin-top: 10px;
      flex: 1 0 100%;
    }
  }
}

p.saved-quizzes-notification {
  margin-bottom: 20px;
}

.saved-quizzes-list {
  list-style: none;
  margin: 0;
  border-radius: 3px;
  border: 1px solid $gray-border;
}

.saved-quizzes-list__item {
  display: block;
  border-bottom: 1px solid $gray-border;
  padding: 20px;

  &:last-of-type {
    border-bottom: 0;
  }

  @media #{$sm} {
    padding: 15px;
  }
}

.saved-quizzes-list__content {
  display: flex;
  align-items: center;

  @media #{$sm} {
    flex-direction: column;
    align-items: stretch;
  }

  & .custom-quiz-progress-circle {
    width: $progress-donut-size;
    height: $progress-donut-size;
    position: relative;
    margin-right: 15px;

    & .progress-donut-container {
      & .colored-doughnut {
        width: $progress-donut-size !important;
        height: $progress-donut-size !important;
      }

      & .fa-graduation-cap {
        color: #E4E4E4;
      }

      & .full-mastery-badge {
        & .fa {
          color: $gold;
        }
      }
    }
  }

  & .completed-badge {
    width: $progress-donut-size;
    height: $progress-donut-size;
    background: $gold;
    @include gold-shimmer(10s);
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    & .fa {
      color: $gold-text;
      font-size: $small-font-size;
    }
  }

  & .progress-donut-icon {
    position: absolute;
    display: inline-flex;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    aspect-ratio: 1;
  }

  & > .saved-quizzes-list__description  {
    flex: 1;
    font-family: var(--medium);
    font-size: $tiny-font-size;
    color: $gray-text;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    & > .saved-quizzes-list__title {
      & .hourglass-tooltip {
        display: none;

        &.show {
          display: block;
        }
      }

      & .fa-hourglass-now {
        color: $red;
      }
    }

    & > .saved-quizzes-list__meta {
      font-family: var(--regular);
      color: $gray-text-light;
      margin-top: 5px;
    }
  }

  .saved-quizzes-list__controls {
    display: flex;
    flex-direction: row;

    @media #{$sm} {
      flex: 1 0 100%;
      margin: 10px 0 0;
    }

    & > .saved-quizzes-list__control {
      margin: 0;

      + .saved-quizzes-list__control {
        margin: 0 0 0 5px;
      }

      &.align-end {
        margin-left: auto;
      }

      & > .fa {
        font-size: 20px;
      }
    }

    & > .saved-quizzes-list__control--start {
      display: flex;
      flex: 1 0 auto;
    }
  }
}

.custom-quiz-landing {
  .custom-quiz-container {
    background-color: $white;
    padding: 50px;

    .title {
      color: $logo-blue;
    }
  }
}

.custom-quiz-summary.preview {
  border: 1px solid $red;
  
  & .preview-banner {
    background-color: $red;
    color: $white;
    text-align: center;
    padding: 12px 15px;
    font-size: 12px;
    line-height: 1.2;

    & .title {
      font-family: var(--regular);
      margin-bottom: 2px;
    }

    & .content {
      font-family: var(--light);

      & .fa {
        margin-left: 4px;
      }
    }

    & a {
      font-size: inherit;
      font-family: var(--medium);
      color: $white;
      text-decoration: underline;
    }

    & .preview-tag {
      display: none;

      & .fa {
        margin-right: 2px;
        position: relative;
        top: -1px;
      }

      @media #{$xs} {
        display: inline-flex;
        padding: 0;
        min-height: 0;
        margin: 0 0 8px;
        font-size: 12px;
      }
    }
  }
}
