.dashbox-grid {
  display: grid;
  grid-gap: 20px;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  margin-bottom: 40px;

  @media #{$sm} {
    display: block;

    & > * {
      margin-bottom: 20px;
    }
    & > *:last-child {
      margin-bottom: 0;
    }
  }
}

.dashbox {
  border: 1px solid $gray-border;
  background-color: #F1F1F1;
  border-radius: 2px;
  color: $gray-text;
  font-size: $tiny-font-size;
  padding: 0 20px;

  &.dashbox--dark {
    border: 1px solid $darker-bg-blue;
    background-color: #394e60;
  }

  & > .dashbox-title {
    line-height: 73px;
    height: 73px;
    text-align: center;
    font-size: 18px;
    color: $logo-blue;
    border-bottom: 1px solid $gray-border;
  }

  & ol {
    counter-reset: item;
    padding: 10px;
    list-style-position: inside;
    line-height: 29px;

    li {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;

      & > a {
        color: $gray-text-light;

        &:hover {
          color: $logo-blue;
        }
      }
    }

    li:before {
        content: counter(item) ". ";
        counter-increment: item;
        color: $logo-blue;
        margin-right: 8px;
    }
  }

  &.dashbox-weakspots {
    height: 355px;

    @media #{$md} {
      height: auto;
    }

    & p {
      font-family: var(--regular);
      font-size: 14px;
      line-height: 24px;
      color: $gray-text-light;
      margin: 30px 10px;
    }
  }
}

// LISTS
// -----

.dashbox-list {
  list-style: none;
  border: 1px solid $gray-border;
  background-color: $gray-bg;
  border-radius: 2px;
  color: $gray-text;
  font-size: $tiny-font-size;
  padding: 0;
  margin: 0 0 15px;

  li + li {
    border-top: 1px solid $gray-border;
  }

  & .dashbox-list__item {
    height: 60px;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & .dashbox-list__title {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 15px;
  }
}

.dashbox-list__view-all {
  display: block;
  text-align: right;
  font-size: $tiny-font-size;
}

.dashbox--jump-back-in {
  margin-bottom: 0;
  background-color: transparent;
  border: 0;
  padding: 0;
  align-self: stretch;

  .dashboard__title {
    text-align: left;
  }
}

// SEARCH BAR
// ----------

.dashbox--search {
  margin-bottom: 40px;
  background-color: transparent;
  border: 0;
  padding: 0;
  align-self: stretch;

  .dashboard__title {
    text-align: left;
  }

  & .dashbox-title {
    line-height: 1.5;
    font-size: 18px;
    border-bottom: none;
    height: auto;
  }

  & form {
    position: relative;
    margin-top: 20px;
    margin-bottom: 0;

    & > input[type=text] {
      margin-top: -8px;
      font-family: var(--regular);
      font-size: 14px;
      padding: 10px 40px 10px 15px;
      border: none;
      background-color: white;
      height: 35px;
      border: 1px solid #e5e5e5;
      box-shadow: 0 2px 4px 0 rgba(0,0,0,0.15);
      min-height: 50px;

      &::placeholder {
        color: $gray-text-lightest;
      }
    }

    & > button[type=submit] {
      width: 45px;
      height: 39px;
      border: none;
      right: 2px;
      position: absolute;
      top: -3px;
      background-color: transparent;
      cursor: pointer;

      & > .fa {
        color: $gray-text-lightest;
        font-size: 20px;
      }
    }
  }
}

// WEAK SPOTS
// ----------

.weak-spot-training {
  padding: 30px 10px;

  .weak-spot-training-right {
    float: right;
    width: 50%;
    display: block;
    padding-left: 30px;
    border-left: 1px solid $gray-border;
    text-align: center;

    & > img {
      max-width: 100%;
    }

    @media #{$sm} {
      float: none;
      width: auto;
      padding-left: 10px;
      padding-right: 10px;
      text-align: center;
      border-left: none;
      margin-bottom: 30px;
    }
  }

  .weak-spot-training-left {
    width: 50%;
    display: block;
    padding-right: 30px;

    @media #{$sm} {
      float: none;
      width: auto;
      padding-left: 10px;
      padding-right: 10px;
      text-align: center;
      border-left: none;
    }
  }

  & p {
    height: 144px;
    margin-bottom: 31px;
    font-family: var(--regular);
    font-size: 14px;
    line-height: 24px;
    color: $gray-text-light;

    @media #{$sm} {
      height: auto;
    }
  }

  & a.btn.btn-block {
    margin-bottom: 0;
  }
}

.dashbox-stats {
  text-align: center;
  padding: 30px 10px;

  .dashbox-stats-number {
    font-size: 60px;
    font-family: var(--light);
    margin-bottom: 30px;
    line-height: 60px;
    color: $logo-blue;
  }
}

// BLANK STATE
// -----------

.dashbox--blank {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: $white;
  border: 1px dashed $gray-border;
  text-align: center;
  padding: 60px 40px;

  @media #{$sm} {
    padding: 30px 15px;
  }

  & > .dashbox__description {
    font-size: $small-font-size;
    margin-bottom: 20px;

    @media #{$sm} {
      font-size: $tiny-font-size;
    }
  }

  & > .btn {
    margin: 0;
  }

  & .dashbox__image {
    margin-bottom: 15px;
  }
}

// TYPEFORM
// --------

#typeform-full {
  @media #{$md} {
    z-index: 1000;
  }
}

// GENERAL
// --------

.dashboard__hero {
  background-color: $gray-bg;
  padding: 40px 0;
  margin-bottom: 40px;
  min-height: 125px;

  @media #{$sm} {
    padding: 30px 0;
    margin-bottom: 20px;
  }
}

.dashboard__welcome {
  font-size: 38px;
  text-align: center;
  color: $gray-text;
  margin-bottom: 0;

  @media #{$sm} {
    font-size: $lead-font-size;
  }
}

.dashboard__jump_back_in {
  border: 1px solid $gray-border;
  border-radius: 2px;
  margin-bottom: 40px;

  & > .trainingunit {
    display: flex;
    align-items: center;
    background-color: $gray-bg;

    &.completed {
      border-left-color: $logo-blue;
      background-color: $gray-bg-dark;
    }

    & .trainingunit__image {
      width: 98px;
      height: 98px;
      position: relative;

      img {
        height: 100%;
        vertical-align: middle;
        text-align: center;
        position: relative;
        border-right: 1px solid $gray-border;
      }

      @media #{$sm} {
        display: none;
      }
    }


    & .trainingunit-topic {
      flex: 1;
      display: flex;
      align-items: center;
      margin-right: auto;
      max-width: 100%;
      min-width: 0;
      gap: 15px;
      padding: 20px;

      @media #{$sm} {
        padding: 15px;
      }

      @media #{$xs} {
        padding-left: 10px;
      }


      & .trainingunit-title {

        & .hourglass-tooltip {
          display: none;

          &.show {
            display: block;
          }
        }

        & .fa-hourglass-now {
          color: $red;
        }
      }
    }

    & .trainingunit-progress-circle {
      width: $progress-donut-size;
      height: $progress-donut-size;
      position: relative;

      & .progress-donut-container {
        & .colored-doughnut {
          width: $progress-donut-size !important;
          height: $progress-donut-size !important;
        }

        & .fa-graduation-cap {
          color: #E4E4E4;
        }

        & .full-mastery-badge {
          & .fa {
            color: $gold;
          }
        }
      }
    }

    & .completed-badge {
      width: $progress-donut-size;
      height: $progress-donut-size;
      background: $gold;
      @include gold-shimmer(10s);
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      & .fa {
        color: $gold-text;
        font-size: $small-font-size;
      }
    }

    & .progress-donut-icon {
      position: absolute;
      display: inline-flex;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 14px;
      aspect-ratio: 1;
    }

    & .trainingunit-details {
      min-width: 0;
      flex: 1;
    }

    & .trainingunit-title {
      font-family: var(--regular);
      font-size: 14px;
      color: $logo-blue;
      margin-bottom: 10px;
      line-height: 18px;

      @media #{$xs} {
        margin-bottom: 5px;
      }

      @media #{$xs} {
        margin-bottom: 0;
      }
    }

    & .trainingunit-description {
      font-family: var(--light);
      font-size: 13px;
      display: inline-block;
      color: $gray-text-light;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-wrap: normal;

      @media #{$xs} {
        display: none;
      }
    }

    & .badge.badge--small {
      font-size: 8px;
      min-height: 13px;
      line-height: 14px;
      padding: 0 8px;
      margin-right: 5px;
    }

    & .trainingunit-cta {
      text-align: right;
      margin-left: 15px;

      @media #{$sm} {

        & .btn.btn-small {
          height: auto;
          padding: 7px;
          font-size: 9px;
        }
      }

      @media #{$xs} {
        margin-left: 10px;
      }
    }
  }
}

.dashboard__due_study_sessions {
  border: 1px solid $gray-border;
  border-radius: 2px;
  margin-bottom: 40px;
  background-color: $gray-bg;

  .due_study_sessions___header {
    flex: 1;
    display: flex;
    align-items: center;

    .dashboard__title {
      flex: 1;
      margin-top: 10px;
      margin-bottom: 10px;
      text-align: left;
      padding-left: 15px;
      font-family: var(--regular);
      font-size: 14px;
      line-height: 18px;
    }

    & .due_study_sessions_header__view_all {
      margin-top: 10px;
      margin-bottom: 10px;
      padding-right: 15px;
      color: $logo-blue;
      font-size: $mini-font-size;
      font-family: var(--regular);
      line-height: 18px;
    }
  }

  .due_study_sessions_list__empty {
    padding: 0 15px;
    margin: 0px;
    color: $gray-text;
  }
}

.due_study_sessions__list {
  list-style: none;
  background-color: $gray-bg;
  color: $gray-text;
  font-size: $tiny-font-size;
  padding: 0 15px;
  margin: 0px;

  li + li {
    border-top: 1px solid $gray-border;
  }

  & .due_study_sessions_list__item {
    height: 60px;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid $gray-border;
    border-radius: 2px;
  }

  & .due_study_session_item__info {
    flex: 1;

    & .due_study_session_item_info__title {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-right: 15px;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      text-wrap: pretty;
    }

    & .due_study_session_item_info__next_recommended_session {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-right: 15px;
      color: $gray-text-light;
      font-size: $mini-font-size;

      &.important {
        color: $red;
      }
    }
  }

  & .due_study_session_item__cta {
    & .due_study_session_item_cta__container {
      display: flex;

      & .due_study_session_item_cta__pause {
        margin-right: 10px;
        padding-top: 10px;

        & form {
          margin-top: 0;
          margin-bottom: 0;

          & button {
            background: none;
            border: none;
            padding: 0;
            cursor: pointer;
          }

          & .fa {
            color: $logo-blue;
          }
        }
      }
    }
  }
}

.dashboard-well {
  background-color: $gray-bg;
  padding: 30px;
  text-align: center;
  border: 0;

  @media #{$sm} {
    padding: 15px;
  }
}

.dashboard-recommended-study-units {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;

  @media #{$md} {
    grid-template-columns: 100%;
  }

  .contentbox {
    & .contentbox-topic {
      padding: 15px;
    }

    & .contentbox-title {
      margin-bottom: 4px;
      font-size: $tiny-font-size;
      line-height: 1.2;
      font-family: var(--regular);
      color: $logo-blue;
      text-align: left;
    }

    & .contentbox-description {
      color: $logo-blue;
      display: inline-flex;
      align-items: center;
      font-size: $mini-font-size;
      font-family: var(--light);
      gap: 4px;
      justify-content: space-between;
      line-height: 1.2;
      text-align: left;

      &::after {
        content: '➞';
      }
    }
  }
}

.dashboard__title {
  font-family: var(--light);
  line-height: 1.5;
  font-size: $standard-font-size;
  text-align: center;
  margin-bottom: 10px;
}

.dashboard__title--large {
  font-family: var(--light);
  line-height: 1.5;
  font-size: $lead-font-size;
  text-align: center;
  margin-bottom: 0;

  @media #{$sm} {
    font-size: $standard-font-size;
  }
}

.dashboard__intro-text {
  font-size: $small-font-size;
  margin-bottom: 30px;

  @media #{$sm} {
    font-size: $tiny-font-size;
    margin-bottom: 15px;
  }
}

.dashboard {
  .topicbox-small-container {
    height: 0;
    opacity: 0;
    transition: opacity .45s ease;
    flex: 0 0 100%;

    &.active {
      height: auto;
      opacity: 1;
    }
  }

  @media #{$sm} {
    .btn-group {
      li {
        flex: 1 1 auto;
      }

      .btn {
        min-width: 100%;
        font-size: 10px;
        padding: 0 10px;
      }
    }
  }
}

// BROWSE TOPICS
// --------

@keyframes highlightTopics {
  0% {
    background-color: $gray-bg-dark;
  }
  50% {
    background-color: $logo-blue-lightest;
  }
  100% {
    background-color: $gray-bg-dark;
  }
}

#browse_by_topic {
  display: block;
  height: 40px;
  margin-top: -40px;
  visibility: hidden;

  @media #{$md} {
    height: 80px;
    margin-top: -80px;
  }
}

.highlight .topicbox__copy {
  animation: highlightTopics 2s .5s;
}

// Back to article toast

.toast {
  position: fixed;
  z-index: 100;
  display: flex;
  align-items: center;
  width: 500px;
  bottom: 30px;
  right: 30px;
  padding: 20px;
  background-color: $dark-bg-blue;
  color: $white;
  border-radius: 3px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
  transform: translate(0, calc(100% + 30px));
  transition: transform .5s ease;

  &.active {
    transform: translate(0);

    &:hover,
    &:focus {
      color: $white;
      transform: translateX(15px);

      .icon {
        color: $logo-blue;
      }
    }
  }

  & .content {
    display: flex;
    align-items: center;
  }

  & .image {
    flex: 0 0 auto;
    width: 80px;
    height: 80px;
    overflow: hidden;

    & img {
      display: block;
      border-radius: 3px;
      max-width: 100%;
      aspect-ratio: 1;
    }

    @media #{$sm} {
      width: 60px;
      height: 60px;
    }
  }

  & .text {
    padding-left: 15px;
    padding-right: 30px;
    line-height: 1.2;

    @media #{$sm} {
      padding-left: 10px;
      padding-right: 15px;
    }
  }

  & .subtitle {
    color: $logo-blue;
    font-size: $tiny-font-size;
    margin-bottom: 2px;

    @media #{$sm} {
      font-size: 12px;
    }
  }

  & .title {
    font-size: $lead-font-size;
    font-family: var(--light);
    width: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;

    @media #{$sm} {
      font-size: $standard-font-size;
    }

    @media #{$xs} {
      font-size: $small-font-size;
    }
  }

  & .icon {
    flex: 0 0 auto;
    font-size: $lead-font-size;
    margin-left: auto;
    transition: color .25s ease;
  }

  @media #{$sm} {
    padding: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    width: auto;
  }
}

.due_study_sessions_modal {
  text-align: center;
  color: $gray-text;
  padding: 30px 50px 15px;

  @media #{$sm} {
    padding: 30px 30px 15px;
  }

  & .modal-header {
    position: relative;
    border-bottom: 0;
  }

  & .close {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    width: 45px;
    height: 45px;
    cursor: pointer;
    background: transparent url("~images/application/crosssign.webp") no-repeat scroll center center;

    @media #{$sm} {
      top: -15px;
      right: -15px;
    }
  }

  .modal-body {
    & .modal_body__title {
      font-weight: bold;
      text-align: left;
    }

    & .modal_body__list {
      .nav-tabs {
        border-bottom: 1px solid $gray-border;
        display: flex;
        margin-bottom: 20px;

        .nav-item {
          text-align: center;

          .nav-link {
            display: block;
            border: 1px solid transparent;
            border-radius: 3px;
            padding: 10px 15px;
            margin-right: 2px;
            cursor: pointer;
            color: $logo-blue;
            text-decoration: none;
            transform: translateY(2px);
            line-height: 1.2;

            @media #{$sm} {
              font-size: $tiny-font-size;
            }

            &.active {
              border-color: $gray-border;
              border-bottom-color: transparent;
              border-radius: 3px 3px 0 0;
              background-color: $white;
            }

            &:hover {
              color: $logo-blue-darkened;
            }
          }
        }
      }

      .tab-pane {
        display: none;

        &.active {
          display: block;
        }

        & .due_study_sessions__list{
          background-color: $white;
          text-align: left;
          padding: 0;

          & .due_study_sessions_list__item {
            border-top: 0;
          }

          & .due_study_sessions_list__item:not(:first-child) {
            border-top: 1px solid $gray-border;
          }
        }
      }

      ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
      }
    }
  }
}
